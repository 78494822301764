




























import { Component, Vue, Watch } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"
@Component({
	components: {
		topNav
	}
})
export default class Default extends Vue {
  addressList:Array<any>=[]
  isClick:boolean=false
  showBalloons: boolean = false

  created() {
    this.isClick=this.$route.query.origin=='order'?true:false;
    this.$api.getAllReceiver().then((res:any)=>{
      this.addressList=res.data;
    })
  }

  goAddressDetail(item:any) {
    this.$router.push({
      path:"/my/receiverAddress",
      query:{
        userInfo:item?encodeURIComponent(JSON.stringify(item)):undefined
      }
    })
  }
  select(item:any){
    if(!this.isClick) return
    console.log(item,'item');
    if(
      !item.receiverName ||
      !item.receiverEmail ||
      !item.province ||
      !item.city ||
      !item.region ||
      !item.town ||
      !item.detailAddress
    ){
      this.showToast('填写完整信息')
      return
    }
    this.$api.receiverSet(item).then((res:any)=>{
      window.location.href = "javascript:history.go(-1)"
    })
  }
  showToast(val:any) {
    this.showBalloons = true
    let data: any = [val, this.showBalloons]
    this.VueFunc.$emit("balloons", data)
    setTimeout(() => {
      this.showBalloons = false
      data = [val, this.showBalloons]
      this.VueFunc.$emit("balloons", data)
    }, 2000)
  }
}

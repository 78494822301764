










import { Component, Vue, Watch, Prop } from "vue-property-decorator"

@Component({
	name: "Default"
})
export default class Default extends Vue {
	@Prop(String) topNavTitle!: string
  @Prop(String) isTwoHandle!: Boolean
	

	created() {
		
	}


	historyBack() {
		const na = localStorage.getItem('na');
    console.log(this.isTwoHandle,333)
    if(this.isTwoHandle) {
      this.backApp()
      this.backWeb()
    } else if (na == location.href){
			this.backApp()
		}else{
			this.backWeb()
		}
	}

  backApp() {
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
    if (u.indexOf("eshebao") != -1) {
      if (isAndroid) {
        ;(window as any).eshebao.gotoBack()
      } else if (isiOS) {
        ;(window as any).webkit.messageHandlers.eshebao.postMessage("gotoBack")
      }
    }
  }
  backWeb() {
    window.location.href = "javascript:history.go(-1)"
  }
}
